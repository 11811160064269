.header_wrapper {
  background: #12121E;

  .header_box {
    padding: 1.5rem 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .header_main_logo{
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .nav_item_wrapper {
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0.8rem 0 0.3rem 0;
    align-items: center;
    .nav_item{
      cursor: pointer;
      margin-left: 3rem;
      &:hover{
        span{
          color: white;
        }
      }
      span{
        font-weight: bold;
       color: #b6b6b6;
      }
    }
  }

  .nav_support_wrapper{
    button{
      background: none;
      color: white;
      border: 1px solid white;
      padding: 0.3rem 3rem;
      font-weight: bold;
      &:hover{
        background: white;
        color: #1D1E2D;
      }
    }
  }

}
