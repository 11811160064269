:root {
    --bright-txt-color: rgba(0, 0, 0, 0.69);
    --bright-bg-color: #F4F8FA;
}


body {
    margin: 0;
    font-family: 'paybooc-Bold', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'paybooc-Bold', sans-serif !important;
}

a {
    text-decoration: none !important;
}

h1, h2, h3, h4, h5 {
    margin: 0!important;
}
