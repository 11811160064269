.minecraft_price_list_wrapper {
  padding: 2rem 0;
  background: #151624;

  .minecraft_price_desc {
    max-width: 880px;
    padding: 2rem 0 2rem 0;
    color: white;
    text-align: center;
    margin: 0 auto;

    h1 {
      font-size: 3rem;
    }

    h4, h1 {
      font-weight: bold;
    }
  }

  .minecraft_price_list {
    padding: 4rem 0 3rem 0;

    .minecraft_price_item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 6rem;
      background: #24253A;
      color: white;

      img {
        position: absolute;
        top: -40px;
      }

      .price_content {
        margin-top: 60px;

        h1, h2 {
          text-align: center;
          font-weight: bold;
        }

        .price_item_month {
          padding: 1rem 0;
        }

        .price_performance {
          text-align: center;
          padding: 1rem 0;

          span {
            font-size: 1.3rem;
            display: block;
          }
        }
      }
    }
  }
}

.minecraft_function_wrapper {
  background: #202131;
  padding: 4rem 0;

  .minecraft_function_title {
    text-align: center;
    margin: 0 auto;
    color: white;
    max-width: 880px;

    h1 {
      font-weight: bold;
    }
  }

  .function_list {
    padding: 3rem 0;
    .function_btn_wrapper {
      display: flex;
      flex-direction: column;

      .function_btn_item {
        text-align: center;
        cursor: pointer;
        padding: 1rem 0;
        color: white;
        font-weight: bold;
        &.select {
          background: #4d99ff;
        }
        span {
          font-size: 1.2rem;
          margin-left: 1.5rem;
        }
      }
    }
    .function_img_wrapper{
      padding: 1rem;
      background: white;
      border-radius: 20px;
      img{
        border-radius: 20px;
        width: 100%;
      }
    }
  }
}


.faq_item_wrapper {
  background: #1D1E2D;
  border-radius: 5px;
  width: 100%;
  padding: 3rem 1rem;

  .minecraft_faq_title{
    padding: 3rem 0;
    color: white;
    text-align: center;
    h1{
      font-size: 3rem;
      font-weight: bold;
    }
  }

  .faq_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    .faq_wrap {
      width: 45%;

      .faq_list_item {
        padding: 0.6rem 0;

        button {
          background: #26283B;
          width: 100%;
          padding: 0.9rem 0.4rem;
          color: white;
          font-weight: bold;
          border: none;
          .faq_item {
            padding: 0 0.3rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .rotate_arrow {
    transform: rotate(0);
  }

  img {
    transform: rotate(-90deg);
    margin-right: 1rem;
    width: 15px;
  }

  .toggle_wrapper {
    background: #26283B;
    padding: 1rem;
    color: white;
    span{
      font-size: 0.875rem;
    }
  }
}
