.nav_footer_wrapper {
  background: #151624;

  .nav_footer_map {
    padding: 5rem 0 3rem 0;

    .footer_item {
      display: flex;
      flex-direction: column;

      .item_title {
        padding: 1rem 0;

        h3 {
          font-weight: bold;
          color: white;
        }
      }

      ul {
        li {
          margin-top: 0.6rem;
          color: #b8b8b8;

          a {
            color: #b8b8b8;
          }
        }
      }

      span {
        margin-top: 0.6rem;
        color: #b8b8b8;
      }
    }
  }

  .footer_foot {
    padding: 1rem 0;
    background: #2f3345;

    span {
      color: white;
    }
  }
}
