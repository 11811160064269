.discount_section {
  background-color: #FF2B35;

  .discount_txt_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;

    #now_go_btn {
      padding: 0.5rem 1rem;
      background: none;
      border: 1px solid white;
      color: white;
      font-weight: bold;

      &:hover {
        background-color: white;
        color: #FF2B35;
      }
    }

    .discount_disc {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    h1 {
      font-size: 1.8rem;
      font-weight: bold;
      margin: 0;
      color: white;
    }

    h4, span {
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
      color: white;
    }

    span {
      margin-left: 2rem;
    }
  }
}


.dashboard_main_section {
  background-size: cover;
  background-repeat: no-repeat;

  .black_back {
    background-color: rgb(18 18 30 / 59%);
  }

  .minecraft_main_section_txt {
    min-height: 350px;
    padding-top: 5rem;
    text-align: center;

    h1 {
      color: white;
      font-weight: bold;
      font-size: 5rem;
    }

    h2 {
      color: white;
      font-weight: bold;
      font-size: 2rem;
    }

    button {
      margin-top: 3rem;
      color: white;
      background-color: rgba(0, 0, 0, .8);
      padding: 15px 2.5rem;
      text-transform: uppercase;
      border: 1px solid #00a9ff;
      font-size: 15px;
      transition: .2s ease-in-out;
      -webkit-transition: .2s ease-in-out;
      cursor: pointer;
      margin-right: 5px;

      &:hover {
        background-color: #00a9ff;
      }
    }
  }

  .main_section_txt {
    padding-top: 5rem;
    max-width: 880px;

    h1 {
      color: white;
      font-weight: bold;
      font-size: 3.5rem;
      padding-bottom: 2rem;
    }

    .main_section_features {
      span {
        font-size: 1.2rem;
        font-weight: bold;
        color: white;
        line-height: 2rem;
      }

      svg {
        fill: #4d99ff;
        margin-right: 0.3rem;
      }
    }

    #main_section_go_btn {
      border: none;
      background: #4d99ff;
      color: white;
      padding: 0.8rem 2rem;
      font-weight: bold;
    }
  }

  .main_section_footer {
    text-align: center;
    color: white;
    padding: 1rem 0 3rem 0;
  }
}

.features_section {
  background: #1D1E2D;

  .features_section_title {
    padding: 3rem 0 2rem 0;

    h1 {
      font-size: 2.7rem;
      font-weight: bold;
      color: white;
    }
  }

  .features_box_wrapper {
    padding-bottom: 3rem;

    .features_box {
      margin-top: 1rem;
      min-height: 265px;

      .features_content {
        height: 100%;
        background: #26283B;
        padding: 2rem;
        text-align: left;
        color: white;

        h4 {
          font-weight: bold;
        }

        .features_desc {
          padding: 1rem 0;
        }
      }
    }
  }
}

.control_panel_section {
  background: #1E202D;

  .control_panel_txt_wrapper {
    color: white;

    h3 {
      font-weight: bold;
    }

    #control_panel_btn {
      margin-top: 2rem;
      border: none;
      background: #1b7dff;
      color: white;
      padding: 0.7rem 2rem;
      font-weight: bold;
    }
  }

  .control_panel_features {
    span {
      margin-top: 0.4rem;
      display: block;
    }
  }

  .control_panel_img_wrapper {
    text-align: right;
    padding: 4rem 0;
  }
}

.dashboard_price_section {
  padding: 3rem ;
  background-color: #34344b;

  .price_title {
    text-align: center;

    h1, span {
      font-weight: bold;
      padding-bottom: 1rem;
    }

    h1 {
      color: white;
    }

    span {
      color: #b0b0b0;
    }
  }

  .price_list {
    padding: 2rem 0;
    .price_item {
      background-size: cover;
      background-repeat: no-repeat;

      .price_content {
        border: 6px solid #27283d;
        min-height: 300px;
        background-color: rgba(58, 61, 82, 0.63);
        padding-top: 2rem;
        text-align: center;
        .price_content_title{
          color: white;
          span{
            font-size: 1.1rem;
          }
        }
        .price_content_main {
          margin-top: 2rem;
          color: white;
          background-color: #2B49FF;

          .bill_info {
            padding: 0.7rem 0;
            small{
              font-size: 1.3rem;
            }
            small,h1{
              font-weight: bold;
            }
          }

          h1 {
            display: inline-block;
          }
        }
        .price_go_btn{
          button{
            border: none;
          background-color: #FF2C35;
          color: white;
          font-weight: bold;
            margin-top: 1rem;
          padding: 0.5rem 3rem;
            &:hover{
              background-color: white;
              color: #FF2C35;
            }
          }
        }
      }
    }
  }


}
